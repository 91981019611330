import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './customFetchBase'

export const jobApi = createApi({
    reducerPath: 'jobApi',
    baseQuery: baseQuery,
    tagTypes: ['Job'],
    endpoints: (builder) => ({
        createJob: builder.mutation({
            query(data) {
                return {
                    url: '/jobs',
                    method: 'POST',
                    body: data,
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        renderJob: builder.mutation({
            query(data) {
                return {
                    url: `/jobs/${data.id}/render`,
                    method: 'POST',
                    body: data,
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                const defaultMessage =
                    'Sorry, something went wrong. Please refresh the page and try again. In case this issue persists, please contact support.'
                if (response.status === 500) {
                    return {
                        status: response.status,
                        message: defaultMessage,
                    }
                }
                return {
                    status: response.status,
                    message: response?.data?.message ?? defaultMessage,
                }
            },
        }),
        getRenders: builder.query({
            query: ({ page = 1, pageSize = 20 } = {}) => ({
                url: `/jobs?page=${page}&pageSize=${pageSize}&include=sceneFile`,
                method: 'GET',
            }),
            transformResponse: (result) => result,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getJobsProgress: builder.query({
            query() {
                return {
                    url: '/jobs/progress',
                    method: 'GET',
                }
            },
            transformResponse: (result) => result,
            transformErrorResponse: (response) => {
                return { message: response.message }
            },
        }),
        getRenderById: builder.query({
            query(id) {
                return {
                    url: `/jobs/${id}?include=sceneFile`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getLastValidatedJob: builder.query({
            query(id) {
                return {
                    url: `/jobs/${id}/last-validated-job`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getJobProgressById: builder.query({
            query(id) {
                return {
                    url: `/jobs/${id}/progress`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => result,
            transformErrorResponse: (response) => {
                return { status: 'error' }
            },
        }),
        getJobDownload: builder.query({
            query: (id) => `/jobs/${id}/download`,
        }),
        cancelJob: builder.mutation({
            query(jobId) {
                return {
                    url: `/jobs/${jobId}/render/cancelation`,
                    method: 'POST',
                    body: {
                        status: 'cancelled',
                    },
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getFrameUrls: builder.query({
            query(id) {
                return {
                    url: `/jobs/${id}/frames/urls`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => result,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getCompositeNodeUrls: builder.query({
            query(id) {
                return {
                    url: `/jobs/${id}/composite-nodes/urls`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => {
                return result
            },
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        validateJob: builder.mutation({
            query(data) {
                return {
                    url: `/jobs/${data.id}/validation/start`,
                    method: 'PATCH',
                    body: data.config,
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        cancelValidation: builder.mutation({
            query(jobId) {
                return {
                    url: `/jobs/${jobId}/validation/cancelation`,
                    method: 'POST',
                    body: {
                        status: 'cancelled',
                    },
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        createFeedback: builder.mutation({
            query(data) {
                return {
                    url: `/jobs/${data.jobId}/feedbacks`,
                    method: 'POST',
                    body: data,
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getFeedback: builder.query({
            query(jobId) {
                return {
                    url: `/jobs/${jobId}/feedbacks`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getUserAddons: builder.query({
            query() {
                return {
                    url: '/users/me/addons',
                    method: 'GET',
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        resumeJob: builder.mutation({
            query(jobId) {
                return {
                    url: `/jobs/${jobId}/resume`,
                    method: 'POST',
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    message: response.data.message,
                }
            },
        }),
        getJobPositionInQueue: builder.query({
            query(jobId) {
                return {
                    url: `/jobs/${jobId}/queue-position`,
                    method: 'GET',
                }
            },
            transformResponse: (result) => result.data,
            transformErrorResponse: (response) => {
                const defaultMessage =
                    'Sorry, something went wrong. Please refresh the page and try again. In case this issue persists, please contact support.'
                return {
                    status: response.status,
                    message: response?.data?.message ?? defaultMessage,
                }
            },
        }),
    }),
})

export const {
    useCreateJobMutation,
    useRenderJobMutation,
    useGetRendersQuery,
    useGetRenderByIdQuery,
    useGetLastValidatedJobQuery,
    useGetJobProgressByIdQuery,
    useGetJobDownloadQuery,
    useGetJobsProgressQuery,
    useCancelJobMutation,
    useGetFrameUrlsQuery,
    useGetCompositeNodeUrlsQuery,
    useValidateJobMutation,
    useCancelValidationMutation,
    useCreateFeedbackMutation,
    useGetFeedbackQuery,
    useResumeJobMutation,
    useGetUserAddonsQuery,
    useGetJobPositionInQueueQuery,
} = jobApi
